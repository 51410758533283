import { render, staticRenderFns } from "./ImgC.vue?vue&type=template&id=cbf6a8ca&scoped=true&"
import script from "./ImgC.js?vue&type=script&lang=js&"
export * from "./ImgC.js?vue&type=script&lang=js&"
import style0 from "./ImgC.scss?vue&type=style&index=0&id=cbf6a8ca&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbf6a8ca",
  null
  
)

export default component.exports