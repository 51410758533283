import Vue from 'vue'
import Vuex from 'vuex'
import general from './modules/general'
import menu from './modules/menu'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        general,
        menu
    }
})

export default store
