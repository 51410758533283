'use strict'
import { createNamespacedHelpers } from 'vuex'
import LazyList from 'lazy-load-list/vue'
import SwiperBlock from '@/components/SwiperBlock/SwiperBlock.vue'
import SlideBlock from '@/components/SwiperBlock/SlideBlock/SlideBlock.vue'

const { mapGetters } = createNamespacedHelpers('menu')

export default {
    name: 'Page',
    components: {
        LazyList,
        SwiperBlock,
        SlideBlock
    },
    data: () => ({
        data: [],
        page: '',
        keyIndex: 0,
        imgs: {
            bio: {
                length: 1,
                portrait: [false]
            },
            avis: {
                length: 22,
                portrait: [false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, false, false]
            },
            etincelle: {
                length: 14,
                portrait: [false, true, false, false, false, false, false, false, false, false, false, false, false, false]
            },
            flammes: {
                length: 9,
                portrait: [false, false, true, false, true, false, false, false, false]
            },
            premierelumiere: { 
                length: 25,
                portrait: [false, true, false, false, false, true, true, true, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
            },
            reflet: {
                length: 30,
                portrait: [false, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
            },
            aura: {
                length: 29,
                portrait: [true, false, false, true, true, true, true, true, false, false, true, true, false, false, false, false, false, true, false, true, true, true, true, true, true, true, true, false, true]
            }
        }
    }),
    computed: {
        ...mapGetters([
            'getPage'
        ])
    },
    watch: {
        getPage(newPage) {
            this.page = newPage
            this.keyIndex++
            
            this.data = []
            for (let i = 1; i <= this.imgs[this.page].length; i++) {
                let data = `${this.page}/${i}`
                this.data.push(data)
            }

            this.$forceUpdate()
        }
    },
    beforeMount() {
        this.page = this.$store.state.menu.page
        for (let i = 1; i <= this.imgs[this.page].length; i++) {
            let data = `${this.page}/${i}`
            this.data.push(data)
        }
    }
}
