'use strict'

import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

import 'swiper/css'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'

export default {
    name: 'Swiper',
    props: {
        name: {
            type: String,
            default: 'swiper--1'
        },
        swiperParams: {
            type: Object
        },
        paginationUnderSlides: {
            type: Boolean
        }

    },
    data: () => ({
        slider: null,
        params: {
            modules: [Navigation, Pagination],
            loop: false,
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            },
            pagination: {
                dynamicBullets: true,
                el: '.swiper-pagination',
                type: 'bullets'
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                1024: {
                  slidesPerView: 'auto',
                  spaceBetween: 10
                }
            }
        }
    }),
    beforeMount() {
        this.params = this.swiperParams ? this.swiperParams : this.params
    },
    mounted() {
        this.slider = new Swiper(`.${this.name}`, this.params)
    }
}
