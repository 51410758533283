import MenuBlock from '@/components/MenuBlock/MenuBlock.vue'
import FooterBlock from '@/components/FooterBlock/FooterBlock.vue'
import LoaderBlock from '@/components/LoaderBlock/LoaderBlock.vue'

export default {
    name: 'app',
    components: {
        MenuBlock,
        LoaderBlock,
        FooterBlock
    }
}
