'use strict'

let state = () => ({
    alertPopinWebapp: false,
    currentDate: null,
    currentPageName: 'One Page',
    previousPageName: 'One Page'
})

const actions = {
    updateCurrentDate: context => context.commit('updateCurrentDate'),
    updateCurrentPageName: (context, newName) => context.commit('updateCurrentPageName', newName),
    updatePreviousPageName: (context, newName) => context.commit('updatePreviousPageName', newName)
}

const mutations = {
    updateCurrentDate: state => {
        const previewDate = new URLSearchParams(window.location.search).get('previewDate')
        if (process.env.NODE_ENV !== 'production' && previewDate) {
            state.currentDate = new Date(previewDate)
        } else {
            state.currentDate = new Date()
            setInterval(() => {
                state.currentDate = new Date()
            }, 1000)
        }
    },
    updateCurrentPageName: (state, newName) => {
        state.currentPageName = newName
    },
    updatePreviousPageName: (state, newName) => {
        state.previousPageName = newName
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
