import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import VueParallaxJs from 'vue-parallax-js'
import VueScrollReveal from 'vue-scroll-reveal'

// App
import App from '@/App.vue'

// Global components
import ImgC from '@/components/ImgC/ImgC.vue'

// Plugins
import i18n from '@/js/plugins/i18n'
import VueScrollTo from 'vue-scrollto'
import { InlineSvgPlugin } from 'vue-inline-svg'

// Conf
import '@/js/conf/'

// Directives
import { timer, timestamp, display, parallax } from '@/js/directives'

// Store
import store from '@/store/'

// Router
import router from './router.js'

// GSAP
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

Vue.config.productionTip = false
Vue.use(VueParallaxJs)
Vue.use(VueScrollTo)
Vue.use(InlineSvgPlugin)
Vue.use(VueScrollReveal)

// Components
Vue.component('ImgC', ImgC)

// Directives
Vue.directive('timer', timer)
Vue.directive('timestamp', timestamp)
Vue.directive('display', display)
Vue.directive('parallax', parallax)

cssVars({
    rootElement: document // default
})

// Force to start at top of the page
window.onbeforeunload = () => {
    window.scrollTo(0, 0)
}

new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')
