'use strict'
import Vue from 'vue'

let config = {
    lang: 'fr'
}

Vue.prototype.$config = config

export default config
