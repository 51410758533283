export const fr = {
    menu: {
        text: 'Photographe <br class="no-mob" />d\'instants de vie',
        title: {
            home: 'Bienvenue',
            reflet: 'Reflet',
            flammes: 'Flammes',
            aura: 'Aura',
            etincelle: 'étincelle',
            premierelumiere: '1ère lumière',
            avis: 'Vos avis',
            bio: 'Qui suis-je ?',
            tarif: 'Tarifs'
        }
    },
    tarif: {
        reflet: {
            title: 'Offre Reflet',
            subtitle: '(portrait)',
            price: '110 €'
        },
        flammes: {
            title: 'Offre Flammes',
            subtitle: '(famille, couple, groupe d\'amis...)',
            price: '130 €'
        },
        etincelle: {
            title: 'Offre Etincelle',
            subtitle: '(grossesse)',
            price: '130 €'
        },
        premierelumiere: {
            title: 'Offre 1<sup>ère</sup> lumière',
            subtitle: '(naissance)',
            price: '150 €'
        },
        aura: {
            title: 'Offre Aura',
            subtitle: '(mariage)',
            price: {
                title: 'Formules de l\'offre Aura',
                price: {
                    1: {
                        title: 'Cérémonies',
                        price: '250 €'
                    },
                    2: {
                        title: 'Préparatifs > fin des cérémonies',
                        price: '600 €'
                    },
                    3: {
                        title: 'Cérémonies > fin du vin d\'honneur',
                        price: '500 €'
                    },
                    4: {
                        title: 'Préparatifs > fin du vin d\'honneur',
                        price: '900 €'
                    },
                    5: {
                        title: 'Ensemble de la journée <br /> (préparatifs > fin de la soirée)',
                        price: '1200 €'
                    }
                }
            },
            price2: {
                1: {
                    title: 'Séance photo "before-wedding" en supplément',
                    price: '110 €'
                },
                2: {
                    title: 'Séance photo "after-wedding" en supplément',
                    price: '110 €'
                },
                3: {
                    title: 'Le pack "before+after-wedding" en supplément',
                    price: '210 €'
                },
                4: {
                    title: 'Album photo en supplément',
                    price: '(prix en fonction du nombre de pages)'
                }
            }
        }
    },
    loader: {
        text: 'expérimentez',
        text2: 'l\'image'
    },
    title: 'Juliette Benhaim',
    bio: `
    Hello les amis !
    <br /><br />
    C'est moi, <span>Juliette Benhaim</span>, la <span>fée de la photographie</span> et l'ambassadrice de
    l'amour ! J'ai récemment posé mes valises à <span>Carcassonne</span>, où les murs de la cité médiévale ne
    sont pas les seuls à avoir une histoire à raconter.
    <br /><br />
    Je suis un vrai cupidon en herbe. Je veux <span>immortaliser vos vies</span>, vos histoires d'amour, votre
    amour-propre, vos mariages, vos <span>précieux moments</span> avec vos bébés et bien d’autres. Je suis une
    véritable chasseuse d'émotions, une conteuse visuelle qui <span>donne vie à vos souvenirs</span> les plus
    chers. Avec moi, vous êtes entre de bonnes mains.
    <br /><br />
    En 2023, j'ai pris la décision audacieuse de me lancer en tant que <span>photographe indépendante</span>. Je
    me suis dit : "Juliette, c'est maintenant ou jamais ! Il est temps de transformer ta passion en
    métier." Bien sûr, je ne suis pas devenue la maîtresse de l'objectif du jour au lendemain. J'ai
    étudié la photographie et le graphisme avec un engouement dévorant. <span>Je suis une diplômée !</span>
    Alors ne vous inquiétez pas, je m'engage à vous fournir des <span>images professionnelles de qualité</span>
    qui vous feront sourire, rire et peut-être même verser une petite larme d'émotion.
    <br /><br />
    Et entre deux séances photos, qu’est-ce que je fais ? Du sport, mes amis ! Je suis une vraie
    sportive dans l’âme.
    Ah, et j'oubliais le meilleur : le chocolat ! Je suis une accro au chocolat, une vraie passionnée de
    ces petites douceurs. Vous voulez me faire plaisir ? Offrez-moi du chocolat et vous verrez un
    sourire radieux illuminer mon visage.
    <br /><br />
    Voilà, vous savez tout sur moi, Juliette Benhaim, la <span>photographe passionnée</span>, sportive du
    dimanche et amatrice de chocolat. Si vous voulez un instant d'amour figé dans le temps, vous
    savez à qui vous adresser. Et n'oubliez pas, la vie est comme un appareil photo : il faut toujours
    se concentrer sur les moments positifs et appuyer sur le bouton du bonheur !
    <br /><br />
    Avec <span>amour et lumière</span>,
    <br /><br />
    Juliette
    `
}
