import Vue from 'vue'
import VueI18n from 'vue-i18n'
import * as locales from './locales'

let messages = {
    'fr': locales['fr']
}

Vue.use(VueI18n)
export default new VueI18n({
    locale: 'fr',
    fallbackLocale: 'fr',
    messages: messages
})
