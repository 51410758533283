import { render, staticRenderFns } from "./SwiperBlock.vue?vue&type=template&id=4003a3ca&scoped=true&"
import script from "./SwiperBlock.js?vue&type=script&lang=js&"
export * from "./SwiperBlock.js?vue&type=script&lang=js&"
import style0 from "./SwiperBlock.scss?vue&type=style&index=0&id=4003a3ca&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4003a3ca",
  null
  
)

export default component.exports