import Vue from 'vue'
import Router from 'vue-router'
import HomeBlock from '@/components/HomeBlock/HomeBlock.vue'
import PageBlock from '@/components/PageBlock/PageBlock.vue'
import TarifBlock from '@/components/TarifBlock/TarifBlock.vue'
import BioBlock from '@/components/BioBlock/BioBlock.vue'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            component: HomeBlock
        },
        { path: '/reflet', component: PageBlock },
        { path: '/flammes', component: PageBlock },
        { path: '/aura', component: PageBlock },
        { path: '/etincelle', component: PageBlock },
        { path: '/premierelumiere', component: PageBlock },
        { path: '/avis', component: PageBlock },
        { path: '/tarif', component: TarifBlock },
        { path: '/bio', component: BioBlock }
    ]
})
