'use strict'
import SwiperBlock from '@/components/SwiperBlock/SwiperBlock.vue'
import SlideBlock from '@/components/SwiperBlock/SlideBlock/SlideBlock.vue'
import { Navigation, Pagination } from 'swiper/modules'

import 'swiper/css'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'

export default {
    name: 'Page',
    components: {
        SwiperBlock,
        SlideBlock,
        Navigation,
        Pagination
    },
    data: () => ({
        params: {
            modules: [Navigation, Pagination],
            loop: false,
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            },
            pagination: {
                dynamicBullets: true,
                el: '.swiper-pagination',
                type: 'bullets'
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0
                }
            }
        }
    })
}
