'use strict'

const state = () => ({
    page: window.location.href.split('/')[4]
})

const getters = {
    getPage: state => state.page
}

const actions = {
    changePage: (context, page) => context.commit('changePage', page)
}

const mutations = {
    changePage: (state, page) => {
        state.page = page
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
