import { render, staticRenderFns } from "./App.vue?vue&type=template&id=137f7e12&scoped=true&"
import script from "./App.js?vue&type=script&lang=js&"
export * from "./App.js?vue&type=script&lang=js&"
import style0 from "./App.scss?vue&type=style&index=0&id=137f7e12&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "137f7e12",
  null
  
)

export default component.exports