'use strict'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('menu')

/**
 * Displays a loading screen before all images are succesfully loaded.<br />
 */
export default {
    name: 'Menu',
    data: () => ({
        menuActive: false
    }),
    ...mapActions([
        'changePage'
    ]),
    methods: {
        changeMenu(index) {
            this.$store.commit('menu/changePage', index)
            this.menuActive = false
        }
    }
}
