import { render, staticRenderFns } from "./LoaderBlock.vue?vue&type=template&id=8c25e8ba&scoped=true&"
import script from "./LoaderBlock.js?vue&type=script&lang=js&"
export * from "./LoaderBlock.js?vue&type=script&lang=js&"
import style0 from "./LoaderBlock.scss?vue&type=style&index=0&id=8c25e8ba&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c25e8ba",
  null
  
)

export default component.exports